import React from 'react'
import Layout from '../components/Layout'
import { graphql } from 'gatsby'
import ReactHtmlParser from 'react-html-parser'
import Img from 'gatsby-image'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import SEO from '../components/Seo'
import ArticleForm from '../components/ArticleForm'

export default ({
	data: {
		wordpressWpArticle: { title, content, featured_media, acf, slug },
	},
}) => {
	return (
		<Layout>
			<SEO title={title} metaTitle='Business Halland' description='Business Halland gör det enklare för dig som företagare att hitta och ta del av de erbjudan och evenemang i Halland.'/>
			<div className="bg-green-accent pt-10 pb-16">
				<div className="container mx-auto px-6 max-w-screen-2xl lg:px-10 xl:px-20">
					<span className="block text-base mb-1 text-white">
						Finansiering
					</span>
					<h1 className="text-2xl leading-tight text-white font-bold mb-4 md:text-3xl lg:text-4xl">
						{title}
					</h1>
				</div>
			</div>
			<div className="container max-w-screen-2xl lg:px-10 xl:px-20 px-6 mx-auto md:pb-10 lg:pb-16">
				<div className="-mt-16 mb-10 max-h-40 article-img md:-mt-12 rounded-img overflow-hidden">
					{featured_media && (
						<Img
							objectFit="contain"
							fluid={
								featured_media.localFile.childImageSharp.fluid
							}
						/>
					)}
				</div>
				<StyledArticle className="w-full mb-16 md:px-8 lg:px-16 md:w-90p">
					{ReactHtmlParser(content)}
				</StyledArticle>
				<div className="w-full mb-16 md:px-8 lg:px-16 md:w-90p">
					{acf.form && (
						<ArticleForm slug={slug} />
					)}
				</div>
			</div>
		</Layout>
	)
}

const StyledArticle = styled.article`
	${tw`text-gray-700`};

	> h1 {
		${tw`font-semibold text-2xl mt-12 mb-3`};
	}

	> h2 {
		${tw`font-semibold text-2xl mt-12 mb-3`};
	}

	> h3 {
		${tw`font-semibold text-xl mt-12 mb-3`};
	}

	> h4,
	h5 {
		${tw`font-semibold text-lg mt-8 mb-3`};
	}

	> p {
		${tw`text-base lg:text-lg leading-normal mb-3`};

		> strong {
			${tw`lg:text-xl`};
		}
		a {
			${tw`text-green-accent font-bold underline`};
		}
	}

	> blockquote {
		${tw`my-12 text-center max-w-xl mx-auto`}

		p {
			${tw`font-semibold text-2xl md:text-3xl my-6`}
		}

		cite {
			${tw`not-italic text-gray-500`}
		}
	}

	> ul {
		${tw`mb-2 py-4 font-bold`}
		li {
			${tw`text-xl md:text-xl mb-2 pl-5 font-bold relative`}
			&:before {
				content: '';
				${tw`h-2 w-2 rounded-full bg-green-accent left-0 top-0 mt-3 absolute block`}
			}
			a {
				${tw`text-green-accent font-bold underline`};
			}
		}
	}

	> div {
		${tw`mt-12 w-full max-w-full`}
		img {
			${tw`w-full max-w-full`}
		}
	}

	> img {
		${tw`mt-12 w-full max-w-full`}
	}

	> figure {
		${tw`mt-12 w-full max-w-full`}
		img {
			${tw`w-full max-w-full`}
		}
		figcaption {
			${tw`my-2 text-sm text-gray-600`}
		}
	}
`

export const query = graphql`
	query($slug: String!) {
		wordpressWpArticle(slug: { eq: $slug }) {
			title
			slug
			content
			acf {
				form
			}
			featured_media {
				title
				caption
				alt_text
				localFile {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
			}
		}
	}
`
