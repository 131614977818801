import React from 'react'
import PropTypes from 'prop-types'

const ArticleForm = ({slug}) => {
	return (
		<form
      className="md:w-3/4 w-full"
      data-netlify="true"
      name={`artikel-${slug}`}
      method="POST"
      netlify-honeypot="bot-field"
      action="/skickat/"
    >
      <input type="hidden" name="form-name" />
      <h3 className="text-lg leading-tight font-bold mb-8">
        Den nominerade:
      </h3>
      <div className="flex md:flex-no-wrap flex-wrap space-x-0 md:space-x-10">
        <div className="md:w-1/2 w-full">
          <input
            className="appearance-none border-b w-full py-2 text-gray-700 bg-transparent border-gray-700 focus:outline-none mb-8 md:mb-16"
            id="namn-1"
            name="namn-1"
            type="text"
            placeholder="Namn*"
            required
          />
        </div>
        <div className="md:w-1/2 w-full">
          <input
            className="appearance-none border-b w-full py-2 text-gray-700 bg-transparent border-gray-700 focus:outline-none mb-8 md:mb-16"
            id="epost-1"
            name="epost-1"
            type="email"
            placeholder="Epost*"
            required
          />
        </div>
      </div>
      <div className="flex md:flex-no-wrap flex-wrap space-x-0 md:space-x-10">
        <div className="md:w-1/2 w-full">
          <input
            className="appearance-none border-b w-full py-2 text-gray-700 bg-transparent border-gray-700 focus:outline-none mb-8 md:mb-16"
            id="tel-1"
            name="tel-1"
            type="tel"
            placeholder="Telefon*"
            required
          />
        </div>
        <div className="md:w-1/2 w-full">
          <input
            className="appearance-none border-b w-full py-2 text-gray-700 bg-transparent border-gray-700 focus:outline-none mb-8 md:mb-16"
            id="kommun-1"
            name="kommun-1"
            type="text"
            placeholder="Kommun*"
            required
          />
        </div>
      </div>
      <div className="flex md:flex-no-wrap flex-wrap space-x-0 md:space-x-10">
        <div className="w-full">
          <textarea
            className="appearance-none border-b w-full py-2 text-gray-700 bg-transparent border-gray-700 focus:outline-none mb-8 md:mb-16"
            rows={1}
            id="nomenering-1"
            name="nomenering-1"
            placeholder="Nomenering*"
            required
          />
        </div>
      </div>
      <h3 className="text-lg leading-tight font-bold mb-8">
        Du som nominerar:
      </h3>
      <div className="flex md:flex-no-wrap flex-wrap space-x-0 md:space-x-10">
        <div className="md:w-1/2 w-full">
          <input
            className="appearance-none border-b w-full py-2 text-gray-700 bg-transparent border-gray-700 focus:outline-none mb-8 md:mb-16"
            id="namn-2"
            name="namn-2"
            type="text"
            placeholder="Namn*"
            required
          />
        </div>
        <div className="md:w-1/2 w-full">
          <input
            className="appearance-none border-b w-full py-2 text-gray-700 bg-transparent border-gray-700 focus:outline-none mb-8 md:mb-16"
            id="epost-2"
            name="epost-2"
            type="email"
            placeholder="Epost*"
            required
          />
        </div>
      </div>
      <div className="flex md:flex-no-wrap flex-wrap space-x-0 md:space-x-10">
        <div className="md:w-1/2 w-full">
          <input
            className="appearance-none border-b w-full py-2 text-gray-700 bg-transparent border-gray-700 focus:outline-none mb-8 md:mb-16"
            id="tel-2"
            name="tel-2"
            type="tel"
            placeholder="Telefon*"
            required
          />
        </div>
        <div className="md:w-1/2 w-full"></div>
      </div>
      <div className="flex items-center justify-end">
        <button
          className="appearance-none border-b font-semibold text-black bg-transparent border-gray-700 focus:outline-none mb-16"
          type="submit"
        >
          Skicka nominering
        </button>
      </div>
    </form>
	)
}

ArticleForm.propTypes = {
	slug: PropTypes.string.isRequired,
}

export default ArticleForm
